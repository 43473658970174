.my-class {
  background-color: beige;
}
.delivery_dt {
  border: 1px solid #bd4a4a;
  width: auto;
  display: inline-block;
  margin-right: 11px;
  padding: 2px 7px;
  border-radius: 3px;
  text-align: center;
  background: #ffd0d0;
  color: #801616;
  margin-left: 10px;
}
.QData {
  white-space: break-spaces;
  max-width: 250px;
}
.message-item{
    vertical-align: middle;
    .serial-no {
        padding: 20px;
    }
}
.new-message {
  background-color: #ececec;
  font-weight: bold;
  border-color: #fff;
}

.viewQueries {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 313px;
    height: 40px;
    border: 1px solid #333;
    border-radius: 4px;
    opacity: 1;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}
