.title_grey {
  font-size: 11px;
  color: #b6abab !important;
}

.pending_button {
  padding: 4px;
  font-Size: 10px;
  width: 56px;
}

.accept {
  background-color: #1cdd80;
}

.toggle_button {
  background-color: white;
  color: #988888 !important;
  border: 1px solid !important;
  width: 128px;
  padding: 3px;
  margin-top: 15px;
}

.display_none {
  display: none;
}

.space {
  margin-left: 10px;
}

.flux_css {
  flex-direction: row;
  /* display: flex; */
  flex-wrap: wrap-reverse;
  margin-top: 25px;
  margin-bottom: 5px;

}

.card_height {
  height: auto !important;
}

.btn-primary:hover {
  // background-color: white !important;
  // /* Green */
  // transition-property: none !important;

}

.text_fontsize {
  FONT-SIZE: 10PX;
}

.Toasttop {
  padding-top: 5% !important;
}

.clickNav {
  cursor: pointer;
}

.tabActive {
  border: 2px solid #ccc;
}

.acceptActive {
  display: block;
}

.acceptHide {
  display: none !important;
}