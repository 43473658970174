.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
  width: 500px !important;
}
.react-drawer-overlay{
  z-index: 10;
}
.react-drawer-drawer{
  z-index: 11;
  background-color: #f9f8f2;
}

.askHelpPopup {
  // padding-top: 80px;
  background-color: #fff;
  .askHelpContain {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    .askHelpTitle {
      padding: 1rem;
      border-bottom: 1px solid var(--highlightBlueLight);
      position: relative;
      text-align: center;
    }
    .askHelpMessageContain {
      padding: 0rem;
      height: 100vh;
      overflow-y: auto;
      position: relative;
      .askHelpRefId {
        position: sticky;
        top: 0px;
        padding: 0.5rem 1rem;
        width: calc(100% - 2rem);
        background: #f9f8f2 0% 0% no-repeat padding-box;
        font-weight: normal;
      }
      .askHelpMessage {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .customerMessage {
          display: flex;
          gap: 1rem;
          .icon {
            padding: 0px;
          }
          .contentData{
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: flex-start;
            .message {
             background-color: #F5F5F5;;
              padding: 0.75rem;
              border-radius: 10px;
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              p {
                line-height: 18px;
                margin-bottom: 0px !important;
              }
              .time {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                font-size: 12px;
                color: var(--primary);
              
              }

              &.read {
                .time {
                  svg {
                    path {
                      fill:  #179fee;
                    }
                  }
                }
              }
            }
          }
         .rplyButton{
          background: #f0fcf8 0 0 no-repeat padding-box;
         display:flex;
         justify-content: flex-end;
         border: 1px solid #50c0a8;
         border-radius: 4px;
         font-size: 14px;
         letter-spacing: .28px;
         opacity: 1;
         padding: 1px 6px;
         cursor: pointer;

          .button {
            border: 1px transparent;
         
            border-radius: 5px;
            color: #eeeeee;
            display: inline-block;
            font-family: Arial;
            font-size: 12px;
           width:50px;
           height:20px;
            text-align: center;
            text-decoration: none;
            margin-left: 20px;
            -webkit-animation: glowing 1300ms infinite;
            -moz-animation: glowing 1300ms infinite;
            -o-animation: glowing 1300ms infinite;
            animation: glowing 1300ms infinite;
          }
          @-webkit-keyframes glowing {
            0% {
              background-color: #0091b2;
              -webkit-box-shadow: 0 0 3px #0091b2;
            }
            50% {
              background-color: #21c7ed;
              -webkit-box-shadow: 0 0 15px #21c7ed;
            }
            100% {
              background-color: #0091b2;
              -webkit-box-shadow: 0 0 3px #0091b2;
            }
          }
          @keyframes glowing {
            0% {
              background-color: #0091b2;
              box-shadow: 0 0 3px #0091b2;
            }
            50% {
              background-color: #21c7ed;
              box-shadow: 0 0 15px #21c7ed;
            }
            100% {
              background-color: #0091b2;
              box-shadow: 0 0 3px #0091b2;
            }
          }
        
          @-webkit-keyframes glowing-polygon {
            0% {
              fill: #0091b2;
              -webkit-filter: drop-shadow( 0 0 3px #0091b2);
            }
            50% {
              fill: #21c7ed;
              -webkit-filter: drop-shadow( 0 0 15px #21c7ed);
            }
            100% {
              fill: #0091b2;
              -webkit-filter: drop-shadow( 0 0 3px #0091b2);
            }
          }
          @keyframes glowingPolygon {
            0% {
              fill: #0091b2;
              filter: drop-shadow( 0 0 3px #0091b2);
            }
            50% {
              fill: #21c7ed;
              filter: drop-shadow( 0 0 15px #21c7ed);
            }
            100% {
              fill: #0091b2;
              filter: drop-shadow( 0 0 3px #0091b2);
            }
          }
        }
      }

        .replayMessage {
          display: flex;
          gap: 1rem;
          .icon {
            padding: 0px;
          }
          .contentData {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: flex-end;
            .message {
              background-color: #F5F5F5;
              padding: 0.75rem;
              border-radius: 10px;
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              p {
                line-height: 18px;
              }
              .user {
                margin-bottom: 2px;
              }
              .time {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                font-size: var(--font-size-small-l);
                color: var(--primary);
                svg {
                  path {
                    fill: var(--neutralDark);
                  }
                }
              }
              &.read {
                .time {
                  svg {
                    path {
                      fill:  #179fee;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .askHelpTextArea {
      padding: 1rem;
      position: relative;
      textarea {
        height: 200px;
      }
      .sendBtn {
        
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      
      }
    }
  }
}
.breaker {  height: 13px !important;
max-width: 100%;
width: 100%;
border: none;
margin: 1rem auto;
background: #bdbdc0;
box-shadow: inset 0px 3px 8px #ebebeb;
}

.messageIcon .badge {
  font-size: 20px;
}


.replyTag {
  border: 1px solid #0091b2;
  padding: 10px;
  .title-wrap{
    display: flex;
    align-content: space-between;
  }
  .icon {
    padding: 0px;
  }
  .contentData {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-end;
    .message {
      background-color: #F5F5F5;
      padding: 0.75rem;
      border-radius: 10px;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      p {
        line-height: 18px;
      }
      .user {
        margin-bottom: 2px;
      }
      .time {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: var(--font-size-small-l);
        color: var(--primary);
        svg {
          path {
            fill: var(--neutralDark);
          }
        }
      }
      &.read {
        .time {
          svg {
            path {
              fill:  #179fee;
            }
          }
        }
      }
    }
  }
}