@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
/* body {
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    overflow-x: hidden;
    padding: 0px;
    margin: 0px;
} */

/*---------------------------------------
    Typorgraphy              
-----------------------------------------*/

.onboard {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .navbar-default,
  .btn {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 1px;
  }

  h1 {
    color: #ffffff;
    font-size: 52px;
    letter-spacing: 2px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }

  h2 {
    color: #f0f0f0;
    font-size: 32px;
  }

  h3 {
    color: #535bd4;
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 4px;
  }

  h4 {
    font-weight: bold;
  }

  p {
    color: #999;
    font-size: 14px;
    font-weight: 300;
    line-height: auto;
  }

  hr {
    border-bottom-color: #999;
    width: 100px;
  }

  .section-btn {
    background-color: #000;
    border: 2px solid transparent;
    border-radius: 100px;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 14px 36px;
    margin-top: 26px;
    transition: all 0.4s ease-in-out;
  }

  .section-btn:hover {
    background: transparent;
    border-top-color: #ffffff;
    border-right-color: #de202c;
    border-bottom-color: #ffffff;
    border-left-color: #de202c;
    color: #ffffff;
  }

  /*---------------------------------------
    General               
-----------------------------------------*/

  html {
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: #535bd4;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none !important;
  }

  a:hover,
  a:active,
  a:focus {
    color: #3ff;
    outline: none;
  }

  :hover {
    transition: 0.3s;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #ffffff;
  }

  .containSection {
    display: flex;
  }

  .leftbar {
    width: 420px;
    background: transparent linear-gradient(163deg, #153071 0%, #0b1839 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 100vh;
    padding: 1rem 0 1rem 1rem;
    position: relative;
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .leftbarNav {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    z-index: 1;
  }

  .leftbarNav li {
    padding: 1rem;
    position: relative;
  }

  .leftbarNav li.Active

  /*.leftbarNav li:hover*/
    {
    background: #ffffff17;
    cursor: pointer;
  }

  .leftbarNav li .activeBdr {
    display: none;
    width: 8px;
    height: 100%;
    background: #ffc33a;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .leftbarNav li.Active .activeBdr

  /*.leftbarNav li:hover .activeBdr */
    {
    display: block;
  }

  .leftbarNav li.Active .leftbarNavCount

  /*.leftbarNav li:hover .leftbarNavCount*/
    {
    border: 1px solid #ffc33a;
    background: #ffc33a;
    color: #132a62;
  }

  .leftbarNavCount {
    border: 1px solid #fff;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    /* font-weight: 800; */
    color: #fff;
    align-items: center;
    justify-content: center;

    font-size: 1.1em;
  }

  .leftbarNav li .steps {
    display: flex;
    gap: 1rem;
  }

  .leftbarNav .leftbarNavSteps {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .leftbarNav .leftbarNavSteps strong {
    font-weight: 400;
    font-size: 18px;
  }

  .sellerHubLogo {
    display: block;
    text-align: center;
    margin-top: 10%;
  }

  .sellerHubLogo img {
    max-width: 100%;
  }

  .sellerHubIcon {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  .loginSection {
    width: calc(100% - 420px);
    min-height: 100vh;
    padding: 1rem;
    background: #f5f5f9 0% 0% no-repeat padding-box;
  }

  .loginNavMob .leftbarNavCountSec {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem 0;
  }

  .loginNavMob .leftbarNavCountSec li {
    padding: 0 2rem;
  }

  .loginNavMob .leftbarNavCountSec li span {
    border: 1px solid #122a62;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    font-weight: 800;
    color: #122a62;
    align-items: center;
    justify-content: center;
  }

  .loginNavMob .leftbarNavCountSec li::marker {
    display: none;
  }

  @media (max-width: 1200px) {}

  @media (max-width: 1024px) {
    .containSection {
      display: block;
    }

    .leftbar {
      width: 100%;
      padding: 0;
      min-height: auto;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 2rem;
    }

    .sellerHubLogo {
      margin-top: 2rem;
    }

    .leftbarNav {
      flex-direction: row;
      gap: 0.5rem;
      justify-content: space-between;
    }

    .sellerHubIcon {
      display: none;
    }

    .loginSection {
      width: calc(100% - 0px);
    }
  }

  @media (max-width: 980px) {}

  @media (max-width: 768px) {
    .loginSection {
      min-height: calc(100vh - 144px);
    }

    .leftbarNav li {
      display: none;
      padding: 0.5rem;
    }

    .leftbarNav li.Active {
      display: block;
      margin: 0 auto;
      width: 100%;
    }

    .sellerHubLogo {
      margin-top: 0.75rem;
    }

    .leftbar {
      gap: 0.75rem;
    }

    .leftbarNav .leftbarNavSteps strong {
      font-size: 16px;
    }

    .leftbarNav .leftbarNavSteps {
      font-size: 0;
      flex-direction: row;
      gap: 0.25rem;
      align-items: center;
    }
  }

  @media (max-width: 767px) {}

  @media (max-width: 650px) {}

  @media (max-width: 480px) {}
}